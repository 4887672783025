import React, { useState } from 'react';
import logoImg from '../../assets/images/future-pathways-logo.png';

export default function Header() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<div className="header w-nav" data-collapse="medium">
			<div className="header-container">
				<a href="https://www.firesidechats.ca/" className="brand w-nav-brand">
					<img src={logoImg} alt="Future Pathways" className="image" />
				</a>
				<nav role="navigation" className="nav-menu w-nav-menu">
					<a href="https://www.firesidechats.ca/careers" className="nav-link w-nav-link">Careers</a>
					<a href="https://www.firesidechats.ca/videos" className="nav-link w-nav-link">Explore</a>
					<a href="https://www.firesidechats.ca/lesson-plans" className="nav-link w-nav-link">Lesson Plans</a>
					<a href="https://www.firesidechats.ca/about" className="nav-link w-nav-link">About</a>
				</nav>
				<div className="div-block-15">
					<div className="menu-button w-nav-button" aria-label="menu" role="button" tabIndex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false" onClick={toggleMenu}>
						<div data-is-ix2-target="1" className="menu-icon" data-w-id="b0e82d15-5c6b-c7d7-bdbe-9a718b50f584" data-animation-type="lottie" data-src="https://cdn.prod.website-files.com/60381b3c722ca5533fb30b5a/60381b3c722ca51396b30bef_lottieflow-menu-nav-11-2-130a2c-easey.json" data-loop="0" data-direction="1" data-autoplay="0" data-renderer="svg" data-default-duration="4.5" data-duration="0">
							{isMenuOpen ? <XIcon /> : <BarIcon />}
						</div>
					</div>
				</div>
			</div>
				<div className={`w-nav-overlay ${isMenuOpen ? 'open' : ''}`} data-wf-ignore="" id="w-nav-overlay-0">
					<nav role="navigation" className="nav-menu w-nav-menu" data-nav-menu-open="">
						<a href="https://www.firesidechats.ca/careers" className="nav-link w-nav-link">Careers</a>
						<a href="https://www.firesidechats.ca/videos" className="nav-link w-nav-link">Explore</a>
						<a href="https://www.firesidechats.ca/lesson-plans" className="nav-link w-nav-link">Lesson Plans</a>
						<a href="https://www.firesidechats.ca/about" className="nav-link w-nav-link">About</a>
					</nav>
				</div>
		</div>
	)
}

const BarIcon = () => {
    return (
        <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M5 7h14M5 12h14M5 17h14"
            />
        </svg>
    )
}

const XIcon = () => {
    return (
        <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24">
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeWidth="2"
                d="M18 6L6 18M6 6l12 12"
            />
        </svg>
    )
}